export const resources = {
  en: {
    translation: {

      language: "Language",

      //HOME PAGE
      agenda: "Agenda",
      presentation: "Presentations",
      speakers: "Speakers",
      participants: "Participants",
      info: "General Information",
      hotel: "Hotel Info",
      feedback: "Feedback",
      organizers: "Organizers",
      galeria: "Gallery",

      //REGISTER PAGE
      register: "Welcome to our Registration Page",
      register_intro:
        'Welcome to the IPOS 60Hz Mexico App. Please register by completing the following the information. If you have any questions please <Link href="mailto:gsv_isv_la_communications.energy@internal.siemens-energy.com?subject=Siemens Energy IPOS 60Hz Mexico">contact us</Link>',
      required: "*Required",
      fullname: "1. Full name*",
      email: "2. E-mail*",
      company: "3. Company*",
      country: "4. What city and country are you coming from?*",
      diet: "5. Do you have any dietary restrictions?",
      accomodation: "6. Do you require any special accommodations?",
      send: "Send",
      registered: "Are you already registered?",
      privacy_title: "Data Privacy Notice",
      privacy:
        'The protection of your data and the recognition of your rights regarding the processing of your personal data is important to Siemens Energy. As part of this event, Siemens Energy may process your personal data in accordance with the Siemens Energy Data Privacy Notice, which can be found <Link href="https://www.siemens-energy.com/mx/es/general/politica-de-privacidad.html">here</Link>',

      //THANK YOU PAGE
      thank_title: "You have been registered successfully",
      thank_message1:
        "We remind you that we have pre-blocked a limited number of rooms with a preferential rate so that you can make your reservation and payment next week when we send you the corresponding link from the host hotel. Thank you very much for your patience.",
      thank_message2:
        'We invite you to download the details of the agenda, scanning the QR code that appears below, with your mobile phone, tablet, or just by clicking <a href="https://ipos-mx.ddns.net/agenda">here</a>',
      thank_message3:
        "Reserve your hotel stay: We remind you that we have pre-blocked a limited number of rooms with a preferential rate. <br/> Make your reservation by following this <a href='https://events.melia.com/es/events/paradisus-cancun/Siemens-Energy-MAR-2023.html'>link</a> de reserva. <b>It is not allowed to book through travel agencies.</b>",
      thank_bullets:
        "<ul><li>Select the dates of your accommodation and your room.</li><li>Once the stay is paid for, you will receive a confirmation email from the hotel with details about the reservation.</li><li>Preferred room: Junior Suite Lagoon View, Single.</li></ul>",

      //AGENDA
      mon_title: "Monday, March 20, 2023",
      mon_register: "Hotel Check-in",
      mon_social: "Register & Welcome Cocktail Hour",
      mon_social_more: "At the Restaurant Santé in the hotel",
      mon_dinner: "Networking Dinner",
      mon_dinner_more: "At the Restaurant Santé in the hotel",
      tue_title: "Tuesday, March 21, 2023",
      tue_register: "Registration (Cont.)",
      tue_register_more: "Room Lanzarote",
      tue_safety: "Safety Moment",
      tue_welcome: "Welcome and Introduction",
      tue_welcome_more:
        "Siemens Energy went public on the Frankfurt Stock Exchange on September 28, 2020 - and is now a young and independent company, albeit with a strong heritage that we are leveraging to build our own, much more agile and focused legacy. <br /> <b> Our culture, our values and behavior are key: we listen to our customers </b> and we adapt our solutions and processes to your needs. <br />",
      tue_siemens: "Siemens Energy | Gas Services",
      tue_siemens_more:
        "Meet the new Gas Service <b>VP for the Latin America Region: Fadi Ghantous </b>",
      tue_sgt5000: "SGT6-5000F: News",
      tue_sgt5000_more: "",
      tue_sgt3000: "SGT6-3000E: News",
      tue_sgt3000_more: "",
      tue_coffee: "Coffee Break",
      tue_hydro: "Hydrogen, Energy Storage Synchronous Condenser",
      tue_hydro_more: "",
      tue_gen: "Generators: Services and News",
      tue_gen_more: "",
      tue_lunch: "Networking Lunch",
      tue_lunch_more: "",
      tue_sgt8000: "SGT6 -8000H",
      tue_sgt8000_more: "",
      tue_sgt9000: "SGT6- 9000HL",
      tue_sgt9000_more: "",
      tue_tbd: "TBD",
      tue_tbd_more: "",
      tue_private: "Breakout sessions - Customers",
      tue_private_more: "",
      tue_groups:
        "Group by models 5000F | 3000E | 2000E | 9000HL | 8000H | 800",
      tue_feedback: "Customer's Feedback via app/Tablets",
      tue_notes: "Take away Day 1",
      tue_break: "Break",
      tue_dinner: "Dinner - Show",
      tue_dinner_more: "",

      wed_title: "Wednesday, March 22, 2023",

      wed_welcome: "Welcome Day 3 /Agenda review",
      wed_sgt800: "SGT-800",
      wed_coffee: "Coffee Break",
      wed_digi: "Digitalization Solutions ",
      wed_field: "Field Service",
      wed_distrib: "Distribution Sales Heads",
      wed_organizers: "Organizing team - acknowledgments",
      wed_closing: "Event closing & Final key take away",
      wed_lunch: "Lunch",
      copyright: "Siemens Energy is a trademark licensed by Siemens AG.",

      dresscodeTitle: "Dress Code",
      climaTitle: "Climate",
      break: "Break", 

      participate: "Thank you for your participation. ", 
      return: "Return to Home",
      use_right_email : "Please use the email you received the invitation from and register before participating in any surveys.", 
      confirm_survey: "Confirm that you wish to send this survey.",
      cancel: "Cancel", 
      continue: "Continue", 
      sendQ : "Send?", 
      geninfo:
        "Grace Alvarez and our Senior Vice President of Services for Latin America, Fadi Ghantous, together with the Siemens Energy Gas Services Latin America team invite you to live the <b> IPOS 60Hz 2023 Mx </b> experience. <br/> <br/>We will explore the technological innovations that Siemens Energy offers for its equipment, the advantages and challenges in Hydrogen, Energy storage, energy transition, among other topics that will be of your interest. <br/> <br/> An event carefully thought for you to enjoy day by day from Monday March 20th, Tuesday 21st and Wednesday 22nd until noon, at the Paradisus Cancun Hotel, in Cancun Mexico.",
      dresscode:
        "The dress code will be casual and/or business casual. <br/> <br/> On Monday 20/03 we will have a welcome cocktail, so guests may attend in casual attire. On March 21 and 22, the dress-code is business casual.",
      clima:
        "The average temperature in Cancun in March is a maximum of 29° C, so it is advisable to <b> wear loose-fitting, cool (light) clothing </b> , preferably in light colors. At night the temperature can drop to 20° C, so a light sweater is recommended.",
      hotelInfo:
        "Address: Boulevard Kukulcan, km 16.5, Cancún <br/><br/> Contact: +52 998 881 1100 / <a href='mailto:paradisus.cancun@melia.com'>paradisus.cancun@melia.com</a> <br/> <br/>Distances: 17 km from airport / 20,2 km from Centro urbano de Cancún",
    },
  },
  es: {
    translation: {
      language: "Idioma",

      //HOME PAGE
      agenda: "Agenda",
      presentation: "Presentaciones",
      speakers: "Expositores",
      participants: "Participantes",
      info: "Información General",
      hotel: "Hotel Info",
      feedback: "Feedback",
      organizers: "Organizadores",
      galeria: "Galería",

      //REGISTER PAGE
      register: "Bienvenido a nuestra página de registro",
      register_intro:
        'Estimado,<br /><br />Bienvenido a la página de IPOS 60Hz Mexico. Regístrese completando la siguiente información. Por favor, en caso de tener alguna pregunta, <a href="mailto:gsv_isv_la_communications.energy@internal.siemens-energy.com?subject=Siemens Energy IPOS 60Hz Mexico">contáctenos.</a>',
      required: "*Requerido",
      fullname: "1. Nombre y apellido*",
      email: "2. Correo electrónico*",
      company: "3. Empresa*",
      country: "4. ¿De qué ciudad y país nos visita?*",
      diet: "5. ¿Tiene alguna restricción alimentaria o alergia que debamos tener en cuenta?",
      accomodation: "6. ¿Requiere asistencia especial de movilidad?",
      send: "Enviar",
      registered: "¿Ya está registrado?",
      privacy_title: "Aviso de privacidad de datos",
      privacy:
        'La protección de sus datos y el reconocimiento de sus derechos con respecto al procesamiento de sus datos personales es importante para Siemens Energy. Como parte de este evento, Siemens Energy puede procesar sus datos personales de acuerdo con el Aviso de privacidad de datos de Siemens Energy, que se puede encontrar <a href="https://www.siemens-energy.com/global/en/general/privacy-notice.html">aquí.</a>',


      //THANK YOU PAGE
      thank_title: "¡Registro exitoso!",
      thank_message1:
        "Le recordamos que hemos pre-bloqueado un cupo limitado de habitaciones con tarifa preferencial para que usted pueda hacer su reserva y pago la próxima semana cuando le enviemos el link correspondiente del hotel anfitrión. Muchas gracias por su paciencia.",
      thank_message2:
        'Lo invitamos a bajar los detalles de la agenda, escaneando el código QR que aparece acá abajo, con su teléfono móvil, tableta, o solo haciendo click <a href="https://ipos-mx.ddns.net/agenda">aquí</a>',
      thank_message3:
        "Reserve y abone su estadía: Le recordamos que hemos pre-bloqueado un cupo limitado de habitaciones con tarifa preferencial. <br/> Ingrese al siguiente <a href='https://events.melia.com/es/events/paradisus-cancun/Siemens-Energy-MAR-2023.html'>link</a> de reserva. <b>No está permitido reservar a través de agencias de viajes.</b>",
      thank_bullets:
        "<ul><li>Seleccione las fechas de alojamiento y su habitación.</li><li>Una vez abonada la estadía, recibirá un correo de confirmación del hotel con detalles sobre la reserva.</li><li>Habitación preferente: Junior Suite Vista Laguna, Single. </li></ul>",

      //AGENDA
      mon_title: "Lunes, 20 de marzo",
      mon_register: "Check-in Hotel",
      mon_social: "Registro  & Cocktail de Bienvenida",
      mon_social_more: "En el restaurant Santé en el hotel",
      mon_dinner: "Cena",
      mon_dinner_more: "En el restaurant Santé en el hotel",
      tue_title: "Martes, 21 de marzo",
      tue_register: "Registro (continuación)",
      tue_register_more: "Salón Lanzarote",
      tue_safety: "Momento de Seguridad",
      tue_welcome: "Bienvenida e Introducción",
      tue_welcome_more:
        "Siemens Energy empezó a cotizar en Frankfurt Stock Exchange el 28 de septiembre de 2020 - y ahora es una empresa joven e independiente, aunque con una fuerte herencia que aprovechamos para construir nuestro propio legado, mucho más ágil y enfocado. Nuestra cultura, nuestros valores y comportamiento son clave: escuchamos a nuestros clientes y adaptamos nuestras soluciones y procesos a sus necesidades. ",
      tue_siemens: "Siemens Energy | Gas Services",
      tue_siemens_more:
        "Escuche de nuestro VP de Servicios para la Región de Latinoamérica Fadi Ghantous, quiénes somos en SE Gas Services y cómo nos hemos reorganizado recientemente para ofrecerle mejores servicios.",
      tue_sgt5000: "Novedades SGT6-5000F",
      tue_sgt5000_more: "",
      tue_sgt3000: "Novedades SGT6-3000E",
      tue_sgt3000_more: "",
      tue_coffee: "Espacio para Café",
      tue_hydro: "Hidrógeno, Almacenamiento de Energía Condensador Síncrono",
      tue_hydro_more: "",
      tue_gen: "Generadores: Novedades y servicios",
      tue_gen_more:
        "Los generadores de Siemens Energy se han caracterizado por su eficiencia, confiabilidad, y flexibilidad operativa. Sin embargo, debido a los cambios operativos del mercado energético en América Latina, Siemens Energy ha desarrollado nuevos productos y servicios para enfrentar este nuevo reto. Esta sesión presenta en forma específica al mercado Latino Americano, como Siemens Energy puede satisfacer los nuevos retos en servicios de generadores.",
      tue_lunch: "Almuerzo",
      tue_lunch_more: "",
      tue_sgt8000: "SGT6 -8000H",
      tue_sgt8000_more: "",
      tue_sgt9000: "SGT6- 9000HL",
      tue_sgt9000_more:
        "Siemens Energy es uno de los tecnólogos líder global de turbinas clase avanzada. Durante esta sesión, escucharemos la evolución de la tecnología Siemens, los recientes éxitos en proyectos que ya operan comercialmente y la descarbonización atribuida por estas turbinas de alta eficiencia. Conozca los aspectos de planta que modularizarían la construcción que directamente aumentan la eficiencia en campo, y a la misma vez mantienen una alta calidad y seguridad de los equipos. Todo esto en conjunto con un programa de servicio y mantenimiento optimizado para el beneficio de los operados y usuarios de nuestros clientes. ",
      tue_tbd: "TBD",
      tue_tbd_more: "",
      tue_private: "Introducción reuniones privadas solo usuarios",
      tue_private_more: "",
      tue_groups:
        "Grupos por modelos 5000F | 3000E | 2000E | 9000HL | 8000H | 800",
      tue_feedback: "Feedback en tabletas vía app",
      tue_notes: "Notas de cierre Dia 2",
      tue_break: "Descanso",
      tue_dinner: "Cena/Show",
      tue_dinner_more: "",
      wed_title: "Miércoles, 22 de marzo ",
      wed_welcome: "Bienvenida día 3, Agenda",
      wed_sgt800: "SGT-800",
      wed_coffee: "Espacio para Café",
      wed_digi: "Digitalización, Soluciones",
      wed_digi_more:
        "Todos estamos conscientes de que los tiempos actuales son bien demandantes. Nuestros socios se están topando con nuevos y desconocidos retos tanto en el área operacional como en el área ambiental, con la necesidad imperante de mantener un balance positivo entre el CAPEX y el OPEX. Las plantas necesitan ser más flexibles, estar disponibles y responder más rápido a la demanda del mercado energético. Plantas de generación están cada día más automatizadas e implementando inteligencia artificial donde la colección, almacenaje y análisis de DATA se está convirtiendo en una tarea extremadamente compleja. Esto aunado a la necesidad de conectividad remota ha generado una necesidad imperante en protegerse de amenazas cibernéticas. Por estas razones, Siemens Energy se complace en presentar su portafolio de soluciones y servicios digitales para ayudar a nuestros clientes a mejorar la eficiencia, aumentar su flexibilidad operativa, cumplir con las nuevas exigencias ambientales, disminuir el OPEX y reducir el riesgo operativo.",
      wed_field: "Field Service",
      wed_distrib: "Representantes de Distribución",
      wed_organizers: "Presentación equipo organizador",
      wed_closing: "Cierre Evento - Notas finales",
      wed_lunch: "Almuerzo",
      copyright:
        "Siemens Energy es una marca comercial registrada bajo licencia de Siemens AG.",

        
        break: "Pausa", 

        // Survey
        confirm_survey: "Confirme que desea enviar esta encuesta.",
        participate: "Gracias por su participación.", 
        return: "Regresar a inicio",
        use_right_email : "Utilice el correo electrónico del que recibió la invitación y regístrese antes de participar en cualquier encuesta.", 
        cancel: "Cancelar", 
        continue: "Continuar", 
        sendQ: "¿Enviar?", 
       
      // Gen Info
      dresscodeTitle: "Código de Vestimenta",
      climaTitle: "Clima",
      geninfo:
        "Grace Alvarez y nuestro Senior Vicepresidente de Servicios para Latinoamérica, Fadi Ghantous, junto al equipo de Siemens Energy Gas Services Latinoamérica lo invitan a vivir la experiencia <b> IPOS 60Hz 2023 Mx. </b> <br/><br/>Exploraremos las novedades tecnológicas que Siemens Energy ofrece para sus equipos, las ventajas y desafíos en Hidrógeno, almacenamiento de Energía, transición energética, entre otros temas que serán de su interés. <br/> <br/> Un evento cuidadosamente pensado para que lo disfrute día a día desde el lunes 20 de marzo, martes 21 y miércoles 22 hasta el mediodía, en el hotel Paradisus Cancún, en Cancún México.",
      dresscode:
        "El código de vestimenta será casual y/o business casual. <br/> <br/> El día lunes 20/03 tendremos un cocktail de bienvenida, por lo que, los invitados pueden concurrir con vestimenta casual. Los días 21 y 22/03, el dress-code es business casual.",
      clima:
        "La temperatura promedio en Cancún en marzo es máxima 29° C, por lo que es conveniente <b> llevar prendas de vestir sueltas y frescas </b> (ligeras); preferiblemente de colores en tonos claros. Por las noches la temperatura puede bajar a 20° C, por lo que se recomienda un suéter ligero.",
      hotelInfo:
        "Dirección: Boulevard Kukulcan, km 16.5, Cancún <br/><br/> Contacto: +52 998 881 1100 / <a href='mailto:paradisus.cancun@melia.com'>paradisus.cancun@melia.com</a> <br/> <br/>Distancias: 17 km desde Aeropuerto / 20,2 km desde Centro urbano de Cancún",
    },
  },
  pt: {
    translation: {
      language: "Língua",
    },
  },
};
