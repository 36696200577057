import * as React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import BackButton from "../components/backbutton";
import { responsiveFontSizes, useTheme } from "@mui/material/styles";
import Companies from "../assets/images/companies.jpg";

// MUI Styling
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";

function createData(name, email, company) {
  return { name, email, company };
}

const users = [
  {
    email: "alejandro.neira@siemens-energy.com",
    company: "Siemens Energy",
    name: "Alejandro Neira",
  },
  {
    email: "edurne.turloff@siemens-energy.com",
    company: "Siemens Energy",
    name: "Edurne Turloff",
  },
  {
    email: "rocio.valle_rivera@siemens-energy.com",
    company: "Siemens Energy",
    name: "Rocío Isaura Valle Rivera",
  },
  {
    email: "taniaaviles-perez@siemens-energy.com",
    company: "Siemens Energy",
    name: "Tania Avilés",
  },
  {
    email: "martin.gomezrecalde@siemens-energy.com",
    company: "Siemens Energy",
    name: "Martin Gomez Recalde",
  },
  {
    email: "jsosa@termochilca.com",
    company: "Termochilca S.A.",
    name: "Javier Ricardo Sosa Ponce",
  },
  {
    email: "grace.alvarez@siemens-energy.com",
    company: "Siemens Energy",
    name: "Grace Alvarez",
  },
  {
    email: "andre.colen@siemens-energy.com",
    company: "Siemens Energy",
    name: "Andre Carrasco",
  },
  {
    email: "sergio.ordonez@termocaribe.co",
    company: "Termocaribe SAS ESP",
    name: "Sergio Ordonez",
  },
  {
    email: "ayrton.abad@siemens-energy.com",
    company: "Siemens Energy",
    name: "Ayrton Abad",
  },
  {
    email: "gelvis.valdez@siemens-energy.com",
    company: "Siemens Energy",
    name: "Gelvis Valdez",
  },
  {
    email: "nsilva@termochilca.com",
    company: "Termochilca S.A.",
    name: "Nick Silva",
  },
  // {
  //   email: "christine.stevens.ext@siemens-energy.com",
  //   company: "Siemens Energy",
  //   name: "Christine",
  // },
  {
    email: "jacobo.navas@siemens-energy.com",
    company: "Siemens Energy",
    name: "Jacobo Navas",
  },
  {
    email: "jorge.luna@siemens-energy.com",
    company: "Siemens Energy",
    name: "Jorge Luna",
  },
  {
    email: "ute.baier@siemens-energy.com",
    company: "Siemens Energy",
    name: "Ute Baier",
  },
  {
    email: "rene.villafuerte@siemens-energy.com",
    company: "Siemens Energy",
    name: "René Villafuerte Hermosillo",
  },
  {
    email: "jrodon@celsia.com",
    company: "Celsia",
    name: "Jose Rodon",
  },
  {
    email: "claudio.rodriguez@siemens-energy.com",
    company: "Siemens Energy",
    name: "Claudio Rodriguez",
  },
  {
    email: "jose.cunado@siemens-energy.com",
    company: "Siemens Energy",
    name: "Jose Cunado",
  },
  {
    email: "ferdinand.velez@siemens-energy.com",
    company: "Siemens Energy",
    name: "Ferdinand Velez",
  },
  {
    email: "rshah@powergen.co.tt",
    company: "PowerGen",
    name: "Raffique Shah",
  },
  {
    email: "creyes@ecoelectrica.com",
    company: "EcoElectrica",
    name: "Carlos Reyes",
  },
  {
    email: "marcio.campos@siemens-energy.com",
    company: "Siemens Energy",
    name: "Marcio Campos",
  },
  {
    email: "rbernengo@msuenergy.com",
    company: "MSU energy & SCC Power",
    name: "Ricardo Fernandez Bernengo",
  },
  {
    email: "rafael.merly@siemens-energy.com",
    company: "Siemens Energy",
    name: "Rafael Merly",
  },
  {
    email: "domingo.enriquez@siemens-energy.com",
    company: "Siemens Energy",
    name: "Domingo Enriquez Guerra",
  },
  {
    email: "joseph.dodd@siemens-energy.com",
    company: "Siemens Energy",
    name: "Joseph Dodd",
  },
  {
    email: "fernando.barrera@termoemcali.com",
    company: "TermoemCali",
    name: "José Fernando Barrera",
  },
  {
    email: "moises.ortiz@siemens-energy.com",
    company: "Siemens Energy",
    name: "MOISES ORTIZ VARGAS",
  },
  {
    email: "ronald.valdivia@endeandina.bo",
    company: "Ende Andina S.A.M",
    name: "Ronald Valdivia",
  },
  {
    email: "pablo.colmenares@siemens-energy.com",
    company: "Siemens Energy",
    name: "Pablo Colmenares",
  },
  {
    email: "matias.maggio@siemens-energy.com",
    company: "Siemens Energy",
    name: "Matias Maggio",
  },
  {
    email: "doc@esentia-energy.com",
    company: "Esentia Energy Systems",
    name: "RAUL MONTEFORTE",
  },
  {
    email: "steven.sandhaas@siemens-energy.com",
    company: "Siemens Energy",
    name: "Steven Sandhaas",
  },
  {
    email: "julio.godreau@siemens-energy.com",
    company: "Siemens Energy",
    name: "Julio E Godreau",
  },
  {
    email: "cbarrazaa@globalpower-generation.com",
    company: "Naturgy",
    name: "Cesar Humberto Barraza Avila",
  },
  {
    email: "alvaro.rincon@siemens-energy.com",
    company: "Siemens Energy",
    name: "Alvaro Rincon",
  },
  {
    email: "alejandro.herrera@siemens-energy.com",
    company: "Siemens Energy",
    name: "Alejandro Herrera",
  },
  {
    email: "nicolas.arroum@siemens-energy.com",
    company: "Siemens Energy",
    name: "Nicolas Arroum",
  },
  {
    email: "felippe.labaki@siemens-energy.com",
    company: "Siemens Energy",
    name: "Felippe Labaki SIlva",
  },
  {
    email: "alejandro.vanegas-palomino@siemens-energy.com",
    company: "Siemens Energy",
    name: "Alejandro Vanegas",
  },
  {
    email: "luis.zambrano@engie.com",
    company: "ENGIE Energia Perú SA",
    name: "Luis Zambrano",
  },
  {
    email: "jjohnson@newfortressenergy.com",
    company: "New Fortress Energy",
    name: "Jervis Johnson",
  },
  {
    email: "mkerr@newfortressenergy.com",
    company: "New Fortress Energy",
    name: "Media Kerr",
  },
  {
    email: "jorge.ayala@aes.com",
    company: "AES Mérida III",
    name: "Jorge Adrían Ayala Martínez",
  },
  {
    email: "dulce.valdes@aes.com",
    company: "AES Mérida III",
    name: "Dulce Carolina Valdes Mujica",
  },
  {
    email: "fadi.ghantous@siemens-energy.com",
    company: "Siemens Energy",
    name: "Fadi Abou Ghantous",
  },
  {
    email: "luis.santana@siemens-energy.com",
    company: "Siemens-Energy",
    name: "Luis Santana",
  },
  {
    email: "eduardo.buendia@cfe.mx",
    company: "Comisión Federal de Electricidad",
    name: "Eduardo H. Buendia Dominguez",
  },
  {
    email: "josemanuel.mendoza@cfe.mx",
    company: "Comisión federal de electricidad",
    name: "Ramón Eduardo Montes N",
  },
  {
    email: "fabio.bindemann@ambarenergia.com.br",
    company: "Âmbar Energia",
    name: "Fábio Tales Bindemann",
  },
  {
    email: "jose.erthal@siemens-energy.com",
    company: "Siemens Energy",
    name: "José Erthal",
  },
  {
    email: "javier.segura@siemens-energy.com",
    company: "Siemens Energy",
    name: "Javier Segura",
  },
  {
    email: "joe.matos@siemens-energy.com",
    company: "Siemens Energy",
    name: "Matos",
  },
  {
    email: "enrique.rosales@cfe.mx",
    company: "CFE",
    name: "Enrique Rosales Sanchez",
  },
  {
    email: "fernando.parodi@siemens-energy.com",
    company: "Siemens Energy",
    name: "Fernando Parodi",
  },
  {
    email: "javier.valenzuela@endeandina.bo",
    company: "ENDE ANDINA",
    name: "Javier Valenzuela",
  },
  {
    email: "jorge.enriquez@cfe.mx",
    company: "CFE",
    name: "Jorge Alberto Enriquez Gomez",
  },
];

export default function Agenda() {
  const [attendeesList, setAttendeesList] = useState([]);
  const theme = useTheme();
  const [imgSize, setImgSize] = React.useState("300");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    if (isMobile) {
      setImgSize("380");
    } else if (isDesktop) {
      setImgSize("750");
    } else setImgSize("500");
  }, [isMobile, isDesktop]);

  let isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

  function widthView() {
    if (isMobileView) {
      return window.width;
    }
    return 360;
  }

  let viewW = widthView();

  function heightView() {
    if (isMobileView) {
      return window.height - 50;
    }
    return 300;
  }

  let viewH = heightView();

  // function getAttendees() {
  //   setAttendeesList([]);

  //   let list = [];
  //   axios
  //     .get("/users")
  //     .then((response) => {
  //       (response.data).forEach((attendee) => {
  //         list.push( createData(attendee.name, attendee.email, attendee.company))
  //       })

  //       setAttendeesList(list);
  //     })
  //     .catch(function (error) {
  //       console.log("This is the error in getAttendees: " + error);
  //     });

  // }

  // useEffect(() => {
  //   getAttendees();
  // }, []);

  function BasicTable() {
    return (
      <TableContainer component={Paper}>
        <Table
          sx={{ maxWidth: 650 }}
          justifyContent="flex-start"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 200 }}>Participante</TableCell>
              <TableCell align="left">Compañia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .sort((a, b) => (a.company > b.company ? 1 : -1))
              .map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" style={{ width: 200 }}>
                    <Typography>
                      {row.name} <br />
                    </Typography>
                    <Typography variant="subtitle"></Typography>
                  </TableCell>
                  <TableCell align="left">{row.company}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Box align="center" sx={{ mt: 3 }}>
      <Grid item xs={12} lg={5} align="center">
        <Typography variant="h5" color="#1B1534">
          Bienvenidos a IPOS 60Hz Mexico
        </Typography>
        <br />
        {/*<img width={imgSize} src={Companies} />*/}
      </Grid>
      <Typography sx={{ mb: 3 }}> This event has ended</Typography>
      <BasicTable />
    </Box>
  );
}
