import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import { Card, CardActions, CardContent } from "@mui/material";
import { LinkOutlined } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: window.innerHeight - 30,
};

export default function EnlargableImage(props) {
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const item = props.item;
  const email = localStorage.getItem("email");
  const [liked, setLiked] = React.useState(item.likes.includes(email));
  const [count, setCount] = React.useState(item.likes.length);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function likePost() {
    setLiked(true);
    item.likes.push(email);

    axios
      .put(`/image/like/${item["_id"]}`, {
        email,
      })
      .then(function (response) {
        console.log(email, " liked ", item["_id"]);
        setCount(response.data.likes.length);
      })
      .catch(function (error) {
        console.error(error);
      });
    //props.setRefresh(true);
  }

  function unlikePost() {
    setLiked(false);
    item.likes = item.likes.filter((e) => e !== email);
    axios
      .put(`/image/unlike/${item["_id"]}`, {
        email,
      })
      .then(function (response) {
        console.log(email, " unliked ", item["_id"]);
        setCount(response.data.likes.length);
      })
      .catch(function (error) {
        console.error(error);
      });
    //props.setRefresh(true);
  }

  return (
    <div>
      <Grid item>
        <Card>
          <CardContent>
            <Button onClick={handleOpen}>
              <ImageListItem key={item.desc}>
                <img
                  src={`${item.url}`}
                  srcSet={`${item.url}`}
                  alt={item.desc}
                  onLoad={() => {
                    setLoaded(true);
                    // props.setLoadCount(loadCount + 1);
                  }}
                />
              </ImageListItem>
            </Button>
          </CardContent>
          <CardActions>
            <Grid container direction="row" alignItems="center" sx={{mr:1}} justifyContent="space-between">
              {/* <Grid item>
                <></>
              </Grid> */}
              <Grid item>
                <Typography> {item.name}</Typography>
              </Grid>
              <Grid item justifyContent="end">
                <Grid container alignItems="center" direction="row">
                  <Grid item>
                    {liked ? (
                      <IconButton onClick={unlikePost}>
                        <ThumbUpIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={likePost}>
                        <ThumbUpOffAltIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography> {count}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container direction="column">
            <Grid container justifyContent="flex-end">
              {/* <Button onClick={handleClose}>Close</Button> */}
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Typography variant="h6" align="center">
              {item.name}
            </Typography>

            <Button onClick={handleClose}>
              <img
                style={{
                  maxHeight: window.innerHeight - 50,
                  maxWidth: window.innerWidth - 50,
                }}
                src={`${item.url}`}
                alt={item.desc}
              />
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
