import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Twitter from "../assets/images/twitter.svg";
import Facebook from "../assets/images/facebook.svg";
import LinkedIn from "../assets/images/linkedin.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mr: 1,
};

export default function AppFooter() {
const navigate = useNavigate(); 
const { t } = useTranslation();

  return (
    <Typography
      component="footer"
      sx={{
        display: "fixed",
        bottom: 0,
        right: 0,
        width: "100%",
        bgcolor: "#1B1534",
      }}
    >
      <Container sx={{ my: 8 }}>
        <Grid container spacing={5} display="flex" flexDirection="column">
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Link
                  underline="hover"
                  color="white"
                  href="https://www.siemens-energy.com/global/en/offerings/power-generation.html"
                >
                  <Typography variant="caption">
                    Power and Heat Generation
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item sx={{ display: "flex" }}>
                    <Box
                      component="a"
                      href="https://twitter.com/siemens_energy"
                      sx={iconStyle}
                    >
                      <img src={Twitter} alt="Twitter" />
                    </Box>
                    <Box
                      component="a"
                      href="https://de.linkedin.com/showcase/siemens-energy"
                      sx={iconStyle}
                    >
                      <img src={LinkedIn} alt="LinkedIn" />
                    </Box>
                    <Box
                      component="a"
                      href="https://www.facebook.com/SiemensEnergy/"
                      sx={iconStyle}
                    >
                      <img src={Facebook} alt="Facebook" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Link
                  underline="hover"
                  color="white"
                  href="https://www.siemens-energy.com/us/en.html"
                >
                  <Typography variant="caption">
                    siemens-energy.com Global Website
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  underline="hover"
                  color="white"
                  href="https://www.siemens-energy.com/global/en/general/corporate-information.html"
                >
                  <Typography variant="caption">
                    Corporate Information
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid item>
              <Divider color="white" sx={{ mt: 5 }} />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="#ada9c2">
              {t("copyright")}
              <br />© Siemens Energy, 2020 - 2021
            </Typography>
          </Grid>
          <Grid item>
            <Link
              component="button"
              color="inherit"
              onClick={() => {
                localStorage.removeItem("email"); 
                navigate()
              }}
            >
              Escape
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
