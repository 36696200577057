import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function LanguageSelect(props) {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = React.useState("es");

  const location = useLocation();

  const label = {
    color: "white",
    "&.Mui-focused": {
      color: "white",
    },
  };

  const styles = {
    select: {
      width: "75px", 
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        color: "white",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        borderWidth: "0.15rem",
        color: "white",
      },
      "&:after": {
        borderColor: "white",
      },
      "& .MuiOutlinedInputBase-root": {
        color: "white",
      },
      "& .MuiSvgIcon-root": {
        color: "white",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "&.Mui-focused": {
        color: "white",
      },
    },
  };

  const labelLight = {
    color: "#1B1534",
    "&.Mui-focused": {
      color: "#1B1534",
    },
  };

  const stylesLight = {
    select: {
  
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#1B1534",
        color: "#1B1534",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1B1534",
        borderWidth: "0.15rem",
        color: "#1B1534",
      },
      "&:after": {
        borderColor: "#1B1534",
      },
      "& .MuiOutlinedInputBase-root": {
        color: "#1B1534",
      },
      "& .MuiSvgIcon-root": {
        color: "#1B1534",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1B1534",
      },
      "&.Mui-focused": {
        color: "#1B1534",
      },
    },
  };

  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
  };

  const handleChangeSurvey = (e) => {
    i18n.changeLanguage(e.target.value);
    props.setLanguage(e.target.value);
  };


  if (props.survey) {
    return (
      <FormControl fullWidth size="small">
          <InputLabel sx={labelLight} id="demo-simple-select-label">
            {t("language")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.language}
            label={t("language")}
            onChange={handleChangeSurvey}
            sx={stylesLight.select}
            inputProps={{ sx: { color: "#1B1534" } }}
          >
            <MenuItem value={"es"}>ES</MenuItem>
            <MenuItem value={"en"}>EN</MenuItem>
          </Select>
        </FormControl>
    )
  }



  return (
    <Box>
      {props.mode && props.mode === "light" ? (
        <FormControl fullWidth size="small">
          <InputLabel sx={labelLight} id="demo-simple-select-label">
            {t("language")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={t("language")}
            onChange={handleChange}
            sx={stylesLight.select}
            inputProps={{ sx: { color: "#1B1534" } }}
          >
            <MenuItem value={"es"}>ES</MenuItem>
            <MenuItem value={"en"}>EN</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <FormControl fullWidth size="small">
          <InputLabel sx={label} id="demo-simple-select-label">
            {t("language")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={t("language")}
            onChange={handleChange}
            sx={styles.select}
            inputProps={{ sx: { color: "white" } }}
          >
            <MenuItem value={"es"}>ES</MenuItem>
            <MenuItem value={"en"}>EN</MenuItem>
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
