import { Link, Box, Container, Grid, Typography } from "@mui/material";

import LatinGlobe from "../../src/assets/images/latinamerica.gif";
import IPOSQRCode from "../../src/assets/images/install_ipos.png";
import Companies from "../assets/images/companies.jpg";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export default function Thanks() {
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <img width="100%" height="auto" src={LatinGlobe} alt="IPOS" />
      </Box>
      <Container
        sx={{ display: "flex", position: "relative" }}
        padding={3}
        align="center"
      
      >
        <Grid container direction="column" align="center">
          <Typography sx={{ mt: 4 }} variant="h3">
            {t("thank_title")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item width="100%">
              <Typography
                variant="h5"
                sx={{ mb: 4, mt: 7, fontWeight: "bold", color: "#1B1534" }}
              ></Typography>
              <Typography
                variant="subtitle1"
                sx={{ mb: 3, mt: 1, color: "#1B1534" }}
              >
                {parse(t("thank_message3"))}
              </Typography>
              <Grid container width="100%" align="center" alignItems="center">
                <Grid item>
                  <Grid container align="left">
                    <Typography>{parse(t("thank_bullets"))}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Typography
                variant="subtitle1"
                sx={{ mb: 4, mt: 3, color: "#1B1534" }}
              >
                {parse(t("thank_message2"))}
              </Typography>
              <img src={IPOSQRCode} alt="IPOS 60Hz Mexico QR" />
              <br />
              <br />
              <br />
              <br />
              <img src={Companies} alt="Company Logos" width="80%" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
