import { Grid, Button, Box, Typography } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Placeholder from "../../src/assets/images/placebutton.png";
import HeaderImage from "../components/headerImage";
import Agenda from "../../src/assets/images/homepage/agenda.png";
import Speakers from "../../src/assets/images/homepage/speakers.png";
import Emergency from "../../src/assets/images/homepage/emergency.png";
import Flight from "../../src/assets/images/homepage/flight.png";
import Info from "../../src/assets/images/homepage/info.png";
import Maps from "../../src/assets/images/homepage/maps.png";
import Participants from "../../src/assets/images/homepage/participants.png";
import Guest from "../../src/assets/images/homepage/special_guest.png";
import Survey from "../../src/assets/images/homepage/survey.png";
import Presentations from "../../src/assets/images/homepage/presentations.png";
import Menti from "../../src/assets/images/homepage/menti.png";
import Staff from "../../src/assets/images/homepage/staff.png";
import Gallery from "../../src/assets/images/homepage/gallery.png";
import Breakout from "../../src/assets/images/homepage/breakout.png";
import Guide from "../../src/assets/images/homepage/guide.png";
import Hotel from "../../src/assets/images/homepage/hotel.png";
import ComingSoon from "../components/ComingSoon";
import LanguageSelect from "../components/langSelect";

export default function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buttons = [
    {
      title: t("agenda"),
      link: "/agenda",
      icon: <CalendarTodayIcon />,
      image: Agenda,
      disabled: false,
    },
    // {
    //   title: "Invitado Especial",
    //   link: "/invitadoespecial",
    //   icon: <CalendarTodayIcon />,
    //   image: Guest,
    //   disabled: false,
    // },
    {
      title: t("presentation"),
      link: "/presentaciones",
      icon: <CalendarTodayIcon />,
      image: Presentations,
      disabled: false,
    },
    {
      title: t("speakers"),
      link: "/expositores",
      icon: <CalendarTodayIcon />,
      image: Speakers,
      disabled: false,
    },
    // {
    //   title: "Your Voice Matters",
    //   link: "/voicematters",
    //   icon: <CalendarTodayIcon />,
    //   image: Guide,
    //   disabled: false,
    // },
    {
      title: t("participants"),
      link: "/participantes",
      icon: <CalendarTodayIcon />,
      image: Participants,
      disabled: false,
    },
    {
      title: "Breakout Session",
      link: "/breakout",
      icon: <CalendarTodayIcon />,
      image: Breakout,
      disabled: false,
    },
    {
      title: t("hotel"),
      link: "/hotelinfo",
      icon: <CalendarTodayIcon />,
      image: Hotel,
      disabled: false,
    },
    {
      title: t("feedback"),
      link: "/encuesta",
      icon: <CalendarTodayIcon />,
      image: Survey,
      disabled: false,
    },
    // {
    //   title: "Menti",
    //   link: "https://www.menti.com/",
    //   icon: <CalendarTodayIcon />,
    //   image: Menti,
    //   disabled: true,
    //   external : true,
    // },
    {
      title: t("info"),
      link: "/info",
      icon: <CalendarTodayIcon />,
      image: Info,
      disabled: false,
    },
    {
      title: t("organizers"),
      link: "/organizadores",
      icon: <CalendarTodayIcon />,
      image: Staff,
      disabled: false,
    },
    // {
    //   title: t("galeria"),
    //   link: "/galeria",
    //   icon: <CalendarTodayIcon />,
    //   image: Gallery,
    //   disabled: true,
    // },
  ];

  return (
    <>
      <HeaderImage />
      <Grid container justifyContent="end">
          <Grid item sx={{ mt: 2, mr: 2 }}>
            <LanguageSelect mode={"light"} />
          </Grid>
        </Grid>
      <Grid container justifyContent="space-between">
        {buttons.map((button) => {
          return (
            <Grid item align="center" xs={4}>
              <Box sx={{ mt: 2, mb: 2 }}>
                {button.disabled ? (
                  <ComingSoon image={button.image} />
                ) : (
                  <Button
                    onClick={() => {
                      button.external
                        ? window.open(button.link, "_blank")
                        : navigate(button.link);
                    }}
                  >
                    <img height={85} src={button.image} />
                  </Button>
                )}
                <Typography color="#1B1534">{button.title}</Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
