import HotelCard from "../components/hotelCard"
import FlightCard from "../components/flightCard"
import Map from "./map"
import {Box} from "@mui/material"

import MapImg from "../assets/images/Map.png"; 
import Security from "../assets/images/HotelSecurity.png"; 




const markers = [  {
    location: "Paradisus Cancún",
    lat: 21.117986,
    lng: -86.75706,
    link: "https://goo.gl/maps/gF4RPK83tHc8AQ347"
  },]; 


export default function FlightHotelInfo() {
    return (
        <>
        
        <HotelCard/>
        <Box align="center">
        <Map markers={markers}  lat={21.117986}  lng={-86.75706}/>
        <img src={MapImg} width="100%"/>
        <img src={Security}  width="100%" />
        </Box>
        </>
    )
}
