import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import SELogo from "../assets/images/logo.svg";
import BackButton from "./backbutton";
import LangSelect from "./langSelect";
import { useLocation } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import {useNavigate} from "react-router-dom"; 
import LangSwitch from "./langSwitch";
const noBackButtonList = ["/home", "/", "/thanks"];
const noLang = ["/expositores", "/organizadores", "/encuesta"]

export default function NavBar(props) {
  const navigate = useNavigate(); 

  // CHANGED TO / INSTEAD OF HOMEPAGE

  const location = useLocation().pathname; 

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} position="fixed" sx={{ bgcolor: "#1B1534" }}>
        <Toolbar>
          {!noBackButtonList.includes(location) ? (
            <Grid
              container
              direction="row"
              sx={{ mt: 2, mb: 2, justifyContent: "space-between" }}
            >
              <Grid item>
                <BackButton />
              </Grid>
              <Grid item>
                <Button onClick={() => {navigate("/")}}>
                <img height={40} src={SELogo} alt="Siemens Energy" />
                </Button>
              </Grid>
              {!noLang.includes(location) ? 
                <Grid item>
                <LangSelect />
              </Grid> : null 
              }
            </Grid>
          ) : (
            <>
              <Grid
                container
                direction="row"
                sx={{ justifyContent: "space-between" }}
                spacing={2}
              >
                <Button onClick={() => {navigate("/")}}>
                <img height={40} src={SELogo} alt="Siemens Energy" />
                </Button>
                {!noLang.includes(location) ? 
                <Grid item>
                <LangSelect />
              </Grid> : null 
              }
              </Grid>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" sx={{ justifyContent: "space-between" }}>
        <Box >
          <img height={40} src={SELogo} alt="Siemens Energy" />
        </Box>
      </Toolbar>
    </Box>
  );
}
