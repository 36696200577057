import * as React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Link,
  Card
} from "@mui/material";
import BackButton from "../components/backbutton";
import { useTheme } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";


// MUI Styling
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Agenda() {
  const theme = useTheme();
  const { t } = useTranslation();

  const agendaData = [
    {
      title: t("mon_title"),
      events: [
        {
          title: t("mon_register"),
          desc: "",
          startTime: "3:00pm",
          timeDetails: " 3:00 - 4:00pm",
        },
        {
          title: t("mon_social"),
          desc: "",
          startTime: "6:00pm",
          timeDetails: " 6:00 - 7:00pm",
          expand: true,
          moreDetails: (
            <>
              <>{t("mon_social_more")}</>
            </>
          ),
        },
        {
          title: t("mon_dinner"),
          desc: "",
          startTime: "7:00pm",
          timeDetails: " 7:00 - 10:00pm ",
          expand: true,
          moreDetails: (
            <>
              <>{t("mon_dinner_more")}</>
            </>
          ),
        },
      ],
    },
    {
      title: t("tue_title"),
      events: [
        {
          title: t("tue_register"),
          desc: "",
          startTime: "8:30am",
          timeDetails: " 8:30 - 9:00am ",
          expand: true,
          moreDetails: parse(t("tue_register_more")),
        },
        {
          title: t("tue_safety"),
          desc: "",
          speaker: "Hotel Staff",
          startTime: "9:00am",
          timeDetails: " 9:00 - 9:10am",
        },
        {
          title: t("tue_welcome"),
          desc: "",
          speaker: "Grace Alvarez",
          startTime: "9:10am",
          timeDetails: " 9:10 - 9:35am",
          expand: true,
          moreDetails: parse(t("tue_welcome_more")),
        },
        {
          title: t("tue_siemens"),
          desc: "",
          speaker: "Fadi Ghantous",
          startTime: "9:35am",
          timeDetails: " 9:35 - 10:00am",
          expand: true,
          moreDetails: parse(t("tue_siemens_more")),
        },
        {
          title: t("tue_sgt5000"),
          desc: "",
          speaker: "Steven Sandhaas​",
          startTime: "10:00am",
          timeDetails: " 10:00 - 11:15am",
          /* expand: true,
          moreDetails: (
            <>
              Siemens Energy es una de las empresas de tecnología energética
              líderes en el mundo. La empresa trabaja con sus clientes y socios en
              sistemas de energía para el futuro, apoyando así la transición hacia
              un mundo más sostenible. Las turbinas de gas de Siemens Energy ya
              son capaces de funcionar con hasta un 100 por ciento de hidrógeno
              verde cuando la infraestructura para los combustibles climáticamente
              neutrales finalmente se instale. Para 2030, las turbinas de gas de
              la compañía estarán 100 por ciento preparadas para hidrógeno, pero
              hasta entonces, necesitamos soluciones que puedan tener un impacto
              inmediato hoy.{" "}
            </>
          ), */
        },
        // {
        //   title: t("tue_sgt3000"),
        //   desc: "",
        //   speaker: "Steven Sandhaas",
        //   startTime: "9:45am",
        //   timeDetails: " 9:45 - 10:30am",
        //   /*  expand: true,
        //   moreDetails: (
        //     <>
        //       Las turbinas de gas de Siemens Energy cumplen con altos requisitos
        //       de un amplio espectro de aplicaciones en términos de eficiencia,
        //       confiabilidad, y flexibilidad mientras cumplen con las normas
        //       ambientales. Dependiendo de sus requisitos, Siemens Energy
        //       proporciona la solución y el alcance adecuado para sus necesidades
        //       específicas del mercado. Con base a los requerimientos del mercado
        //       en América Latina, esta sesión se enfoca en mejoras de potencia,
        //       eficiencia y flexibilidad para las turbinas de gas 4000F y 2000E.
        //     </>
        //   ), */
        // },
        {
          title: t("tue_coffee"),
          desc: "",
          startTime: "11:15am",
          timeDetails: "11:15 - 11:45am",
        },
        {
          title: t("tue_hydro"),
          desc: "",
          speaker: "Pablo Colmenares",
          startTime: "11:45am",
          timeDetails: " 11:45 - 1:00pm",
          /* expand: true,
          moreDetails: (
            <>
              Las turbinas de gas de Siemens Energy cumplen con altos requisitos
              de un amplio espectro de aplicaciones en términos de eficiencia,
              confiabilidad, y flexibilidad mientras cumplen con las normas
              ambientales. Dependiendo de sus requisitos, Siemens Energy
              proporciona la solución y el alcance adecuado para sus necesidades
              específicas del mercado. Con base a los requerimientos del mercado
              en América Latina, esta sesión se enfoca en mejoras de potencia,
              eficiencia y flexibilidad para las turbinas de gas 4000F y 2000E.
            </>
          ), */
        },
        {
          title: t("tue_lunch"),
          desc: "",
          startTime: "1:00pm",
          timeDetails: "1:00 - 2:15pm ",
        },

        {
          title: t("tue_sgt9000"),
          desc: "",
          speaker: "Jorge Luna",
          startTime: "2:15pm",
          timeDetails: " 2:15 - 3:30pm ",
           expand: true,
          moreDetails: t("tue_sgt9000_more")
        },
        {
          title: t("tue_gen"),
          desc: "",
          speaker: "Julio Godreau",
          startTime: "3:30am",
          timeDetails: "3:30 - 4:30pm",
          expand: true,
          moreDetails: t("tue_gen_more"),
        },
        // {
        //   title: "TBD",
        //   desc: "",
        //   speaker: "",
        //   startTime: "3:15pm",
        //   timeDetails: " 3:15 - 15:45pm ",
        //   /* expand: true,
        //   moreDetails: (
        //     <>
        //       Hemos pensado en otras opciones que se ofrecen en Argentina. ¿Ha
        //       pensado en diversificarse o al menos evaluar otras opciones de
        //       negocios? Esta presentación de Ing. Aranguren abrirá al diálogo para
        //       entender mejor el modelo de negocio y las oportunidades que puedan
        //       presentarse en el área de la minería.{" "}
        //     </>
        //   ), */
        // },
        {
          title: t("tue_coffee"),
          desc: "",
          startTime: "4:30pm ",
          timeDetails: "4:30 - 4:45pm",
        },
        {
          title: t("wed_digi"),
          desc: "",
          speaker: "Jacobo Nava",
          startTime: "4:45pm ",
          timeDetails: "4:45 - 5:45pm ",
           expand: true,
          moreDetails: t("wed_digi_more")
        },
        // {
        //   title: t("tue_private"),
        //   desc: "",
        //   speaker: "Grace Alvarez",
        //   startTime: "4:00pm",
        //   timeDetails: " 4:00 - 4:15pm",
        //   /* expand: true,
        //   moreDetails: (
        //     <>
        //       Siemens Energy es uno de los tecnólogos líder global de turbinas
        //       clase avanzada. Durante esta sesión, escucharemos la evolución de la
        //       tecnología Siemens, los recientes éxitos en proyectos que ya operan
        //       comercialmente y la descarbonización atribuída por estas turbinas de
        //       alta eficiencia. Conozca los aspectos de planta que modularizarían
        //       la construcción que directamente aumentan la eficiencia en campo, y
        //       a la misma vez mantienen una alta calidad y seguridad de los
        //       equipos. Todo esto en conjunto con un programa de servicio y
        //       mantenimiento optimizado para el beneficio de los operados y
        //       usuarios de nuestros clientes.{" "}
        //     </>
        //   ), */
        // },
        // {
        //   title: t("tue_groups"),
        //   desc: "",
        //   speaker: "Usuarios",
        //   startTime: "4:15pm",
        //   timeDetails: " 4:15 - 5:35pm",
        // },
        // {
        //   title: t("tue_feedback"),
        //   desc: "",
        //   speaker: "Moderators",
        //   startTime: "4:45pm ",
        //   timeDetails: "4:45 - 4:55pm  ",
        // },
        {
          title: t("tue_notes"),
          desc: "",
          speaker: "Joe Matos, Fernando Parodi, Moises Ortiz",
          startTime: "5:45pm ",
          timeDetails: " 5:45 - 6:15pm ",
        },
        // {
        //   title: "Transporte al restaurante ",
        //   desc: "",
        //   speaker: "Todos",
        //   startTime: "19:30 ",
        //   timeDetails: " 19:30 -  ",
        // },
        {
          title: t("break"),
          desc: "",
          speaker: "Todos",
          startTime: "6:15pm ",
          timeDetails: " 6:15 - 7:30pm ",
        },
        {
          title: t("tue_dinner"),
          desc: "",
          speaker: "Todos",
          startTime: "7:30pm ",
          timeDetails: " 7:30 - 10:00pm ",
        },
      ],
    },
    {
      title: t("wed_title"),
      events: [
        {
          title: t("wed_welcome"),
          desc: "",
          speaker: "Grace Alvarez",
          startTime: "9:00am",
          timeDetails: "9:00 - 9:15am  ",
        },
        {
          title: t("wed_sgt800"),
          desc: "",
          speaker: "Álvaro Rincon",
          startTime: "9:15am ",
          timeDetails: "9:15 - 10:30am ",
          /* expand: true,
          moreDetails: (
            <>
              La turbina de gas industrial SGT-800 combina un diseño simple y
              robusto para alta confiabilidad y fácil mantenimiento, con alta
              eficiencia y bajas emisiones. La evolución progresiva de la SGT-800
              basada en la experiencia de la flota, análisis de diseño, productos
              de mejora y soluciones de servicio, hace que sea un producto
              completo para generación eléctrica y cogeneración.
              <br /> La capacidad de uso de combustibles verdes como parte de la
              fuente de combustible dentro de la turbina nos ubica en un camino
              correcto para lograr nuestro objetivo de cero emisiones netas previo
              a 2030.
            </>
          ), */
        },
        {
          title: t("wed_coffee"),
          desc: "",
          speaker: "Todos",
          startTime: "10:30am ",
          timeDetails: "10:30 - 10:45am",
        },
        {
          title: t("wed_field"),
          desc: "",
          speaker: "Joseph Dodd",
          startTime: "10:45am ",
          timeDetails: "10:45 - 11:00am ",
          /* expand: true,
          moreDetails: (
            <>
              En Siemens Energy reconocemos los requerimientos y necesidades
              operativas de nuestros clientes, a través de una historia les
              invitamos a conocer las actividades en las estamos involucrados, qué
              hacemos y cómo lo hacemos. Conocerán algunos datos interesantes
              sobre nuestras operaciones y principalmente qué estamos haciendo
              para estar cerca de Ustedes, nuestros clientes.
            </>
          ), */
        },
        {
          title: t("wed_distrib"),
          desc: "",
          speaker: "Gelvis & Ana",
          startTime: "11:00am ",
          timeDetails: "11:00 - 11:30am ",
        },
        {
          title: t("wed_organizers"),
          desc: "",
          speaker: "Grace Alvarez",
          startTime: "11:30am ",
          timeDetails: "11:30 - 11:40am ",
        },
        {
          title: t("wed_closing"),
          desc: "",
          speaker: "Fadi Ghantous",
          startTime: "11:40am ",
          timeDetails: "11:40 - 12:30pm",
        },
        {
          title: t("wed_lunch"),
          desc: "",
          startTime: "12:30am",
          timeDetails: "12:30 - 1:30pm ",
        },
      ],
    },
  ];

  let isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

  function widthView() {
    if (isMobileView) {
      return window.width;
    }
    return 360;
  }

  let viewW = widthView();

  function heightView() {
    if (isMobileView) {
      return window.height - 50;
    }
    return 300;
  }

  let viewH = heightView();

  return (
    <Box sx={{mt:3}}  >
      <Grid
        container
        direction="row"
        padding={2}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h5" color="#1B1534">
            Agenda
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <IconButton
                onClick={() => {
                  window.location.reload();
                }}
              >
                {" "}
                <RefreshIcon />{" "}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {agendaData.map((day, idx) => (
          <li key={`section-${idx}`}>
            <ul>
              <Typography sx={{ml:3}} variant="h5">{day.title}</Typography>
              {day.events.map((event, idx) => (
                <>
                  <Grid container direction="row" justifyContent="flex-start">
                    <Divider />
                    <ListItem key={`event-${idx}`}>
                      {event.expand ? (
                        <Card sx={{borderRadius: 4,  width: {xs: "100%", sm: "450px"}}}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={1}
                          padding={2}
                        >
                          <Grid item xs={2}>
                            <ListItemText primary={event.startTime} />
                          </Grid>
                          <Grid item xs={10}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                            >
                              <Accordion elevation={0} spacing={0} padding={0}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon sx={{ color: "#641E8C" }} />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Grid container direction="column">
                                    <Grid item>
                                      <ListItemText
                                        primaryTypographyProps={{
                                          fontSize: "20px",
                                        }}
                                        primary={event.title}
                                      />
                                    </Grid>
                                    {event.speaker ? (
                                      <Grid item>
                                        <ListItemText
                                          sx={{ fontSize: 8 }}
                                          primary={event.speaker}
                                        />
                                      </Grid>
                                    ) : null}
                                    <Grid item>
                                      <ListItemText
                                        primaryTypographyProps={{
                                          fontSize: "14px",
                                        }}
                                        primary={event.timeDetails}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container justifyContent="flex-start" >
                                    <Grid item xs={12}>
                                      <Typography>
                                        {event.moreDetails}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        </Grid>
                        </Card>
                      ) : (
                        <Card sx={{borderRadius: 4, height: 150, width: {xs: "100%", sm: "450px"}}}>
                        <Grid container direction="row" spacing={1} padding={2}>
                          <Grid item xs={2}>
                            <ListItemText primary={event.startTime} />
                          </Grid>
                          <Grid item xs={10}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              sx={{ml:2}}
                            >
                              <Grid item>
                                <ListItemText
                                  primaryTypographyProps={{ fontSize: "20px" }}
                                  primary={event.title}
                                />
                              </Grid>
                              {event.speaker ? (
                                <Grid item>
                                  <ListItemText
                                    sx={{ fontSize: 8 }}
                                    primary={event.speaker}
                                  />
                                </Grid>
                              ) : null}
                              <Grid item>
                                <ListItemText
                                  primaryTypographyProps={{ fontSize: "14px" }}
                                  primary={event.timeDetails}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        </Card>
                      )}
                    </ListItem>
                  </Grid>
                </>
              ))}
            </ul>
          </li>
        ))}
        <Divider />
      </List>
      </Grid>
      </Grid>
    </Box>
  );
}
