import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import NavBar from "./components/navbar";
import Agenda from "./pages/agenda";
import Attendees from "./pages/attendees";
import Emergency from "./pages/emergency";
import FlightHotelInfo from "./pages/flighthotelinfo";
import Guest from "./pages/guest";
import Home from "./pages/homepage";
import Info from "./pages/info";
import Map from "./pages/map";
import Register from "./pages/register";
import Speakers from "./pages/speakers";
import Survey from "./pages/survey";
import Trivia from "./pages/trivia";
import Thanks from "./pages/thanks";
import Products from "./components/products";
import SingleInfo from "./components/singleInfo";
import AppFooter from "./components/footer";
import { Box } from "@mui/material";
import MapPage from "./pages/mapPage";
import axios from "axios";
import { useState, useEffect } from "react";
import AdminPage from "./pages/admin";
import Presentations from "./pages/presentations";
import Gallery from "./pages/gallery";
import Organizers from "./pages/organizers";
import TagManager from "react-gtm-module";
import NotAvailable from "./components/notAvailable";
import Splash from "./pages/splash";

const tagManagerArgs = {
  gtmId: "G-K0SPHBNVFK",
};

// removed /home
const noNavBar = ["/", "/register"];

function App() {
  // TODO: Add Theme Provider
  // TODO route the pages
 //axios.defaults.baseURL = "https://seipos-mx.herokuapp.com/";
//  axios.defaults.baseURL = "http://localhost:5000/";

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(true);

  TagManager.initialize(tagManagerArgs);
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: window.location.href,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    });
  }, [location]);

  const navigate = useNavigate();

  const goToHome = ["/", "/register"];

  useEffect(() => {
    // const email = localStorage.getItem("email");

    // if (email && email.length > 0 && goToHome.includes(location.pathname)) {
    //   navigate("/");
    // }
    setLoading(false);
  }, []);

  function PrivateRoutes() {
    const email = localStorage.getItem("email");
    if (email) {
      return <Outlet />;
    } else {
      return <Navigate to={"/"} />;
    }
  }

  return (
    <>
      {!loading ? (
        <>
          <Box
            sx={{
              height: "100%",
              minHeight: "100vh",
            }}
          >
            {!noNavBar.includes(location.pathname) ? <NavBar  /> : null}

            <Routes>
              {/* <Route path="/" element={<Splash />} /> */}
              <Route path="/register" element={<Register />} />
              {/* <Route path="/admin" element={<AdminPage />} /> */}

              {/* <Route element={<PrivateRoutes />}> */}
                <Route path="/agenda" element={<Agenda />} />
                <Route path="/expositores" element={<Speakers />} />
                <Route path="/participantes" element={<Attendees />} />
                <Route path="/info" element={<Info />} />
                <Route path="/invitadoespecial" element={<Guest />} />
                <Route path="/hotelinfo" element={<FlightHotelInfo />} />
                <Route path="/encuesta" element={<Survey surveyId={1} />} />
                <Route path="/breakout" element={<Survey surveyId={2} />} />
                {/* <Route path="/encuesta" element={<NotAvailable />} />
                <Route path="/voicematters" element={<NotAvailable />} /> */}
                <Route path="/thanks" element={<Thanks />} />
                <Route path="/organizadores" element={<Organizers />} />
                {/* <Route path="/galeria" element={<Gallery />} /> */}
                <Route path="/presentaciones" element={<Presentations />} />
                <Route path="/" element={<Home />} />
              {/* </Route> */}
            </Routes>
          </Box>
          <Box>
            <AppFooter />
          </Box>
        </>
      ) : null}
    </>
  );
}
export default App;
