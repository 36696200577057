import * as React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MapPage from "./mapPage";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";



export default function SimpleAccordion() {
  const { t } = useTranslation();

  const tabs = [
    {
      title: t("info"),
      content: parse(t("geninfo"))
    },
    {
      title: t("dresscodeTitle"),
      content: parse(t("dresscode"))
    },
    {
      title: t("climaTitle"),
      content: parse(t("clima"))
    },
    {
      title: "Hotel", 
      content: parse(t("hotelInfo"))
    }
  ];



  return (
    <Box sx={{mt:3}}>
      {tabs.map((tab) => {
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">{tab.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{tab.content}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
