import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Header from '../assets/images/headerImage.png'


export default function HeaderImage() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));


    if (matches) {
        return (
            <Box >
                <img
                    width='100%'
                    height='auto'
                    src={Header}
                    alt="IPOS"
                />
            </Box>
        );
    }
    else {
        return (
            <Box >
                <img
                    width='100%'
                    height='auto'
                    src={Header}
                    alt="IPOS"
                />
            </Box>
        );

    }

}