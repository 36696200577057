import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CEP from "../assets/images/cep.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import i18next from "i18next";
import LanguageSelect from "../components/langSelect";

// MUI Components
import {
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardActions,
  CardContent,
  Button,
  Slider,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material/";

// MUI Styling
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";

const MOBILEBOX = 350;
const DESKTOPBOX = 850;
let boxSize = DESKTOPBOX;

const survey1 = [
  {
    _id: "641470a3621abd8333ffa898",
    question_id: 1,
    answer_type: "rank",
    survey_id: 1,
    question_desc_en:
      "How likely is it that you would recommend Siemens Energy to a colleague or business partner?",
    question_desc_es:
      "¿Qué tan probable es que usted recomiende Siemens Energy a un colega o socio comercial?",
    choices: [],
    createdAt: "2023-03-17T13:52:35.740Z",
    updatedAt: "2023-03-17T13:52:35.740Z",
    __v: 0,
  },
  {
    _id: "6414cfb3621abd8333ffa8da",
    question_id: 2,
    answer_type: "text",
    survey_id: 1,
    question_desc_en:
      "How can Siemens Energy help you build your business strategy?",
    question_desc_es:
      "¿Cómo Siemens Energy le podría ayudar a usted a construir su estrategia?",
    choices: [],
    createdAt: "2023-03-17T20:38:11.470Z",
    updatedAt: "2023-03-17T20:38:11.470Z",
    __v: 0,
  },
  {
    _id: "6414cfd5621abd8333ffa8dc",
    question_id: 3,
    answer_type: "text",
    survey_id: 1,
    question_desc_en:
      "How can Siemens Energy help you make your resources perform better while optimizing your operations at the same time?",
    question_desc_es:
      "¿Cómo Siemens Energy le podría ayudar a que sus recursos rindan mejor y a la vez optimizar sus operaciones?",
    choices: [],
    createdAt: "2023-03-17T20:38:45.826Z",
    updatedAt: "2023-03-17T20:38:45.826Z",
    __v: 0,
  },
  {
    _id: "641db701146e7dab35dc3b5e",
    question_id: 4,
    answer_type: "text",
    survey_id: 1,
    question_desc_en:
      "Are you interested in participating in an exclusive session to go through specific topics about the Customer-Siemens Energy relationship? Please mention the topics.",
    question_desc_es:
      "¿Le interesaría tener una sesión exclusiva en la cual se puedan revisar temas específicos a la relación Cliente-Siemens Energy? ¿Cuáles temas?",
    choices: [],
    createdAt: "2023-03-24T14:43:13.818Z",
    updatedAt: "2023-03-24T14:43:13.818Z",
    __v: 0,
  },
  {
    _id: "6414d026621abd8333ffa8e0",
    question_id: 5,
    answer_type: "text",
    survey_id: 1,
    question_desc_en:
      "Is there any other topic or concern you would like to share with us?",
    question_desc_es:
      "¿Hay algún otro tema o preocupación que le gustaría compartir con nosotros?",
    choices: [],
    createdAt: "2023-03-17T20:40:06.816Z",
    updatedAt: "2023-03-17T20:40:06.816Z",
    __v: 0,
  },
];

const survey2 = [
  {
    _id: "6414f08683d0df41e489d590",
    question_id: 1,
    answer_type: "rank",
    survey_id: 2,
    question_desc_en:
      "On a scale from 1 to 10, did you find the information we presented to you today useful? (Taking into consideration that 1 is very dissatisfied and 10 is very satisfied)",
    question_desc_es:
      "En una escala del 1 al 10, ¿le ha parecido útil la información que le hemos presentado hoy? (Teniendo en consideración que 1 es muy insatisfecho y 10 es muy satisfecho)",
    choices: [],
    createdAt: "2023-03-17T22:58:14.723Z",
    updatedAt: "2023-03-17T22:58:14.723Z",
    __v: 0,
  },
  {
    _id: "6414f2d283d0df41e489d592",
    question_id: 2,
    answer_type: "text",
    survey_id: 2,
    question_desc_en:
      "How can we improve the information presented by Siemens Energy during the event?",
    question_desc_es:
      "¿Cómo podemos mejorar la información presentada por Siemens Energy durante el evento?",
    choices: [],
    createdAt: "2023-03-17T23:08:02.741Z",
    updatedAt: "2023-03-17T23:08:02.741Z",
    __v: 0,
  },
  {
    _id: "6414f2f583d0df41e489d594",
    question_id: 3,
    answer_type: "text",
    survey_id: 2,
    question_desc_en: "What topics would you like to hear at the next IPOS?",
    question_desc_es: "¿Qué temas le gustaría escuchar en el próximo IPOS?",
    choices: [],
    createdAt: "2023-03-17T23:08:37.611Z",
    updatedAt: "2023-03-17T23:08:37.611Z",
    __v: 0,
  },
  {
    _id: "6414f37283d0df41e489d596",
    question_id: 4,
    answer_type: "mult",
    survey_id: 2,
    question_desc_en:
      "Which presentation did you find most impactful and useful for your business?",
    question_desc_es:
      "¿Cuál presentación le pareció más impactante y útil para su negocio?",
    choices: [
      "Siemens Energy | Gas Services - Fadi Ghantous",
      "Novedades SGT6-5000F - Steven Sandhass",
      "Hidrógeno, Almacenamiento de Energía Condensador Síncrono - Pablo Colmenares",
      "SGT6- 9000HL - Jorge Luna",
      " Generadores: Novedades y servicios - Julio Godreau",
      "Digitalización, Soluciones - Jacobo Nava ",
      "SGT-800",
      "Field Service - Joseph Dodd",
    ],
    createdAt: "2023-03-17T23:10:42.879Z",
    updatedAt: "2023-03-17T23:10:42.879Z",
    __v: 0,
  },
  {
    _id: "6414f3a483d0df41e489d598",
    question_id: 5,
    answer_type: "text",
    survey_id: 2,
    question_desc_en:
      "Regarding logistics, what suggestions would you like to share with the organizers of the event?",
    question_desc_es:
      "¿En cuanto a la logística, que sugerencias le gustaría compartir con el equipo organizador del evento?",
    choices: [],
    createdAt: "2023-03-17T23:11:32.281Z",
    updatedAt: "2023-03-17T23:11:32.281Z",
    __v: 0,
  },
];
export default function Survey(props) {
  const [surveys, setSurveys] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMSG, setErrorMSG] = useState("");
  const { t } = useTranslation();

  const [language, setLanguage] = useState("es");

  const navigate = useNavigate();
  const theme = useTheme();
  // for submit confirmation
  const [open, setOpen] = React.useState(false);

  let isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

  if (isMobileView) {
    boxSize = MOBILEBOX;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // getSurvey(props.surveyId);
    if (props.surveyId === 1) setSurveys(survey1);
    else setSurveys(survey2);
  }, []);

  function getSurvey(id) {
    axios
      .get(`/survey/?id=${id}`)
      .then(function (response) {
        const surveyData = response.data;
        const defaultAnswers = [];
        setSurveys(surveyData);

        surveyData.map((question) => {
          let answer = {};
          if (question.answer_type === "rank") {
            answer = {
              question: question.question_id,
              answer: "5",
              question_desc_es: question.question_desc_es,
              question_desc_en: question.question_desc_en,
              answer_type: question.answer_type,
            };
          } else if (question.answer_type === "check") {
            answer = {
              question: question.question_id,
              answerChoice: [],
              question_desc_es: question.question_desc_es,
              question_desc_en: question.question_desc_en,
              answer_type: question.answer_type,
            };
          } else {
            answer = {
              question: question.question_id,
              answer: "",
              question_desc_es: question.question_desc_es,
              question_desc_en: question.question_desc_en,
              answer_type: question.answer_type,
            };
          }
          defaultAnswers.push(answer);
        });
        setAnswers(defaultAnswers);
      })
      // Handle an error response from the server (we had issuses creating our preset)
      .catch(function (error) {
        console.log(error);
      });
  }

  // onChange={(e) => { handleAnswerChange(e, idx) }}

  function handleAnswerChange(event, idx) {
    const newAnswers = [...answers];
    newAnswers[idx].answer = event.target.value;
    setAnswers(newAnswers);
    console.log("UPDATED" + JSON.stringify(answers));
  }

  function handleSubmit() {
    setError("This event has ended.");
    return;

    const email = localStorage.getItem("email");
    axios
      .post(`/results/create`, {
        email,
        surveyId: props.surveyId,
        answers: answers,
      })
      .then(function (response) {
        setSubmitted(true);
      })
      // Handle an error response from the server (we had issuses creating our preset)
      .catch(function (error) {
        setError(true);
        setSubmitted(true);
      });
  }

  // onChange={(e) => { handleAnswerChange(e, idx) }}
  function handleAnswerChangeCheck(event, idx) {
    const newAnswers = [...answers];
    let choices = newAnswers[idx].answerChoice;
    let newChoices = [];
    console.log("event" + event.target.value);

    if (choices.includes(event.target.value)) {
      newChoices = choices.filter((e) => e !== event.target.value);
    } else {
      newChoices = [...choices, event.target.value];
    }
    newAnswers[idx].answerChoice = newChoices;

    //newAnswers[idx].answer = event.target.value;
    setAnswers(newAnswers);
    console.log("UPDATED" + JSON.stringify(answers));
  }
  function handleAnswer(idx, type, choices) {
    if (type === "rank") {
      return (
        <Box sx={{ minWidth: 300 }}>
          <Slider
            aria-label="Answer"
            defaultValue={5}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={10}
            sx={{ color: "#1B1534" }}
            onChange={(e) => {
              handleAnswerChange(e, idx);
            }}
          />
        </Box>
      );
    } else if (type === "mult") {
      return (
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={(e) => {
              handleAnswerChange(e, idx);
            }}
          >
            {choices.map((choice) => {
              return (
                <FormControlLabel
                  value={choice}
                  control={<Radio />}
                  label={choice}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      );
    } else if (type === "check") {
      return (
        <FormControl>
          {choices.map((choice) => {
            return (
              <FormControlLabel
                value={choice}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon sx={{ color: "#1B1534" }} />}
                  />
                }
                label={choice}
                onChange={(e) => {
                  handleAnswerChangeCheck(e, idx);
                }}
              />
            );
          })}
        </FormControl>
      );
    } else {
      return (
        <TextField
          placeholder="Escriba aquí"
          multiline
          style={{ width: 800 }}
          onChange={(e) => {
            handleAnswerChange(e, idx);
          }}
        />
      );
    }
  }

  return (
    <>
      <Grid container justifyContent="end">
        <Grid item sx={{ mt: 6, mr: 2 }}>
          <LanguageSelect
            survey={true}
            language={language}
            setLanguage={setLanguage}
            mode={"light"}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {surveys.map((question, idx) => {
          return (
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <Grid item xs={12}>
                <Card sx={{ minWidth: boxSize, maxWidth: boxSize }}>
                  <CardContent>
                    {language === "es" ? (
                      <Typography>
                        Pregunta {question.question_id}:{" "}
                        {question.question_desc_es}{" "}
                      </Typography>
                    ) : (
                      <Typography>
                        Pregunta {question.question_id}:{" "}
                        {question.question_desc_en}{" "}
                      </Typography>
                    )}
                    {question.answer_type === "mult"
                      ? question.choices.map((choice) => {
                          return <ul>
                            <li> {choice}</li>
                          </ul>;
                        })
                      : null}
                  </CardContent>
                  {/* <CardActions>
                    {handleAnswer(idx, question.answer_type, question.choices)}
                  </CardActions> */}
                </Card>
              </Grid>
            </Grid>
          );
        })}

        <Typography sx={{mb:3}}>
          This event has ended. 
        </Typography>
      </Box>
    </>
  );

  return (
    <>
      <Grid container justifyContent="end">
        <Grid item sx={{ mt: 6, mr: 2 }}>
          <LanguageSelect
            survey={true}
            language={language}
            setLanguage={setLanguage}
            mode={"light"}
          />
        </Grid>
      </Grid>
      {submitted ? (
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ padding: 4 }}>
            {error ? (
              <Typography> {t("use_right_email")} </Typography>
            ) : (
              <Typography> {t("participate")} </Typography>
            )}
          </Box>
          <Button
            onClick={() => {
              navigate("/home");
            }}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("return")}
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {surveys.map((question, idx) => {
            return (
              <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
                p={2}
              >
                <Grid item xs={12}>
                  <Card sx={{ minWidth: boxSize, maxWidth: boxSize }}>
                    <CardContent>
                      {language === "es" ? (
                        <Typography>
                          Pregunta {question.question_id}:{" "}
                          {question.question_desc_es}{" "}
                        </Typography>
                      ) : (
                        <Typography>
                          Pregunta {question.question_id}:{" "}
                          {question.question_desc_en}{" "}
                        </Typography>
                      )}
                    </CardContent>
                    <CardActions>
                      {handleAnswer(
                        idx,
                        question.answer_type,
                        question.choices
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            );
          })}
          <Button
            onClick={handleClickOpen}
            variant="contained"
            sx={{ borderRadius: 8, backgroundColor: "#1B1534", mb: 2 }}
          >
            {t("send")}
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"> {t("sendQ")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("confirm_survey")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("cancel")}</Button>
              <Button onClick={handleSubmit} autoFocus>
                {t("continue")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
}
