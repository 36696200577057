import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function BackButton() {

    const navigate = useNavigate(); 
    // CHANGED FROM /home
    return (
        <IconButton onClick={()=> {navigate("/")}}>
            <ArrowBackIosIcon sx={{color: "white"}} />
        </IconButton>
    )
}