import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Link,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import Header from "../assets/images/headerImage.png";
import axios from "axios";
import LanguageSelect from "../components/langSelect";

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [attendance, setAttendance] = useState("");
  const [dietary, setDietary] = useState("");
  const [accommodations, setAccommodations] = useState("");
  const [sheraton, setSheraton] = useState("true");
  const [hotelName, setHotelName] = useState("Paradisus Cancún");
  const [country, setCountry] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const { t } = useTranslation();

  const ERROR_MSG = "Por favor rellene este campo.";
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^\S+@\S+\.\S+$/);
  };

  const handleSubmit = () => {
    if (validateEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }


    setErrorMsg("The registration period has expired");
    return;
    setSubmitted(true);
    if (
      name.length < 1 ||
      email.length < 1 ||
      company.length < 1 ||
      country.length < 1
    ) {
      console.log("Invalid Input");
      setErrorMsg("Por favor complete todos lo campos.");
      return;
    }

    axios
      .post("/users/register", {
        email: email.toLowerCase(),
        name,
        company,
        hotel: "",
        accommodations,
        attendance,
        dietary,
        country,
      })
      .then(function (response) {
        if (!response.data.invited) {
          setErrorMsg(
            "Por favor utilice el correo electrónico del que recibió la invitación. Si sigue teniendo problemas por favor contactarse con gsv_isv_la_communications.energy@internal.siemens-energy.com"
          );
          return;
        } else if (response.data.userAlreadyRegistered) {
          localStorage.setItem("email", email.toLowerCase());
          navigate("/home"); 
          return;
        }

        // console.log("response in registerUser:", response);

        localStorage.setItem("email", email.toLowerCase());
        navigate("/thanks");
      })
      // Handle an error response from the server (we had issuses creating our preset)
      .catch(function (error) {
        console.log(error);
        console.log(`${email}, ${name}, ${company}`);
      });
  };


  return (
    <Box align="center">
      <Grid container>
        <Grid item xs={12}>
          <img width="100%" height="auto" src={Header} alt={"SEIPOS+ Header"} />
        </Grid>
      </Grid>
      <Grid container justifyContent="end">
          <Grid item sx={{ mt: 2, mr: 2 }}>
          <LanguageSelect mode={"light"} />
          </Grid>
        </Grid>

      <Box component="form" noValidate autoComplete="off" padding={3}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction="column"
              spacing={4}
              align={"left"}
              sx={{ minWidth: "100%" }}
            >
              <Grid item>
                <Grid container align="left" direction="column" padding={1}>
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{}}>
                       {t("register")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container align="left" direction="column" padding={1}>
                  <Grid item xs={12}>
                    <Typography>
                     {parse(t("register_intro"))}
                    </Typography>
                    <Typography>
                    {t("required")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container align="left" direction="column" padding={1}>
                  <Grid item xs={12}>
                    <Typography>{t("fullname")}</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      id="name"
                      label="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={submitted && name === ""}
                      helperText={submitted && name === "" ? ERROR_MSG : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ minWidth: 200 }}>
                <Grid container align="left" direction="column" padding={1}>
                  <Grid item>
                    <Typography>{t("email")}</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      id="email"
                      label="Email*"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={submitted && (emailError || email === "")}
                      helperText={
                        submitted && (emailError || email === "")
                          ? "Por favor introduzca una dirección de correo electrónico válida."
                          : " "
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ minWidth: 200 }}>
                <Grid container align="left" direction="column" padding={1}>
                  <Grid item>
                    <Typography>{t("company")}</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      id="company"
                      label="Company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      error={submitted && company === ""}
                      helperText={submitted && company === "" ? ERROR_MSG : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ minWidth: 200 }}>
                <Grid container align="left" direction="column" padding={1}>
                  <Grid item>
                    <Typography>
                      {t("country")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      id="country"
                      label="City, Country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      error={submitted && country === ""}
                      helperText={submitted && country === "" ? ERROR_MSG : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/*this needs to implemeented in backend!!*/}
              {/* <Grid item xs={12} sx={{ minWidth: 200 }}>
                <Grid container align="left" direction="column" padding={1}>
                  <Grid item>
                    <Typography>
                      5. Hemos diseñado especialmente este evento para 3 días.
                      Si tiene comentarios adicionales sobre su asistencia,
                      díganos aquí.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      id="attendance"
                      label="Attendance comments"
                      value={attendance}
                      onChange={(e) => setAttendance(e.target.value)}
                      error={submitted && attendance === ""}
                      helperText={
                        submitted && attendance === "" ? ERROR_MSG : " "
                      }
                    />
                  </Grid>
                </Grid>
              </Grid> */}

              {/* <Grid item xs={12} sx={{ mt: 4, minWidth: 200 }}>
                <Grid container direction="column" padding="1" align="left">
                  <Grid item>
                    <Typography>5. ¿En qué hotel se hospedará?*</Typography>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={"Paradisus Cancún"}
                        onChange={(e) => {
                          if (e.target.value === "true") {
                            setHotelName("Paradisus Cancún");
                            setSheraton("true");
                          } else {
                            // setHotelName("");
                            setSheraton("false");
                          }
                        }}
                        value={sheraton}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value={"true"}
                          control={
                            <Radio
                              sx={{
                                color: "#1B1534",
                                "&.Mui-checked": {
                                  color: "#1B1534",
                                },
                              }}
                            />
                          }
                          label="Paradisus Cancún (Preferente)"
                        />
                        <FormControlLabel
                          value={"false"}
                          control={
                            <Radio
                              sx={{
                                color: "#1B1534",
                                "&.Mui-checked": {
                                  color: "#1B1534",
                                },
                              }}
                            />
                          }
                          label="Otro"
                        />
                        {sheraton === "true" ? null : (
                          <TextField
                            fullWidth
                            id="Other"
                            label="Other*"
                            value={hotelName}
                            onChange={(e) => setHotelName(e.target.value)}
                            error={submitted && hotelName === ""}
                            helperText={
                              submitted && hotelName === "" ? ERROR_MSG : " "
                            }
                          />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid item xs={12} sx={{ minWidth: 200 }}>
                <Grid container align="left" direction="column" padding={1}>
                  <Grid item>
                    <Typography>
                      {t("diet")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      id="dietary"
                      label="Dietary Restrictions"
                      value={dietary}
                      onChange={(e) => setDietary(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ minWidth: 200 }}>
                <Grid container align="left" direction="column" padding={1}>
                  <Grid item>
                    <Typography>
                      {t("accomodation")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      id="accommodations"
                      label="Special Accommodations"
                      value={accommodations}
                      onChange={(e) => setAccommodations(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {errorMsg.length > 0 ? (
                <Grid item>
                  <Typography color="red">{errorMsg}</Typography>
                </Grid>
              ) : null}
              <Grid item>
                <Grid container direction="column" align="center">
                  <Box color="white">
                    <Button
                      variant="contained"
                      color="inherit"
                      sx={{
                        borderRadius: 8,
                        backgroundColor: "#1B1534",
                        mb: 2,
                      }}
                      onClick={handleSubmit}
                    >
                      {t("Enviar")}
                    </Button>
                  </Box>
                  <Link color="#1B1534" href={"/"}>
                    {t("registered")}
                  </Link>
                </Grid>
              </Grid>

              <Grid item>
                <Typography variant="h5">
                  {t("privacy_title")}
                </Typography>
              </Grid>

              <Grid item>
                <Typography>
                {parse(t("privacy"))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
