import Presentation from "../components/presentation";

import Placeholder from "../assets/images/placeholder.png";
import Intro from "../assets/images/presentations/Intro.png";
import Dodd from "../assets/images/presentations/Dodd.png";
import Digi from "../assets/images/presentations/Digi.png";
import FuelFlex from "../assets/images/presentations/800FuelFlex.png";
import Services from "../assets/images/presentations/800Services.png";
import Generadores from "../assets/images/presentations/Generadores.png";
import SGT5000 from "../assets/images/presentations/sgt6-5000.png";
import Decarb from "../assets/images/presentations/decarb.png";
import HLClass from "../assets/images/presentations/HLClass.png";
import SGT800 from "../assets/images/presentations/sgt800.png";
import Welcome from "../assets/images/presentations/Welcome.png";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export default function Presentations() {

  const { t } = useTranslation();

  return (
    <>
    <Presentation
        image={Welcome}
        title={t("tue_welcome")}
        content={
        <>
             {t("tue_title")}<br />
          9:10 - 9:35am.<br />
        </>
        }
        link ="/pdfs/IPOS_Welcome.pdf"
        
        color = "#1B1534"
        textColor = "white"
        side = "right"
        author = "Grace Alvarez"
      />
      <Presentation
        image={Intro}
        title={t("tue_siemens")}
        content={
        <>
            {t("tue_title")}<br />
          9:35 - 10:00am.<br />
        </>
        }
        link ="/pdfs/IPOS_Intro_Ghantous.pdf"
        
        color = "#f0f0f0"
        textColor = "black"
        side = "left"
        author = "Fadi Ghantous"
      />
      <Presentation
        image={SGT5000}
        title={t("tue_sgt5000")}
        content={
        <>
             {t("tue_title")}<br />
          10:00 - 11:15am.<br />
        </>
        }
        link ="/pdfs/IPOS_SGT5000F_Sandhaas.pdf"
        color = "#1B1534"
        textColor = "white"
        side = "right"
        author = "Steven Sandhaas"
      />
      <Presentation
        image={Decarb}
        title={t("tue_hydro")}
        content={
        <>
             {t("tue_title")}<br />
          11:45 - 1:00pm.<br />
        </>
        }
        link ="/pdfs/IPOS_Decarb_Colmenares.pdf"
        
        color = "#f0f0f0"
        textColor = "black"
        side = "left"
        author = "Pablo Colmenares"
      />
      <Presentation
        image={HLClass}
        title={t("tue_sgt9000")}
        content={
        <>
           {t("tue_title")}<br />
          2:15 - 3:30pm.<br />
        </>
        }
        link ="/pdfs/IPOS_HLclass_Luna.pdf"
        color = "#1B1534"
        textColor = "white"
        side = "right"
        author = "Jorge Luna"
      />
      <Presentation
        image={Generadores}
        title={t("tue_gen")}
        content={
        <>
            {t("tue_title")}<br />
          3:30 - 4:30pm.<br />
        </>
        }
        link =""
        
        color = "#f0f0f0"
        textColor = "black"
        side = "left"
        author = "Julio Godreau"
      />
       <Presentation
        image={Digi}
        title={t("wed_digi")}
        content={
        <>
            {t("tue_title")}<br />
          4:45 - 5:45pm <br />
        </>
        }
        link ="/pdfs/IPOSNava_Digitalization.pdf"
        color = "#1B1534"
        textColor = "white"
        side = "right"
        author = "Jacobo Nava"
      />
      <Presentation
        image={SGT800}
        title={t("wed_sgt800")}
        content={
        <>
          {t("wed_title")}<br />
          9:15 - 10:30am<br />
        </>
        }
        link ="/pdfs/SpanishSGT-800Service Products.pdf"
        
        color = "#f0f0f0"
        textColor = "black"
        side = "left"
        author = "Álvaro Rincon"
      />
        <Presentation
        image={Dodd}
        title={t("wed_field")}
        content={
        <>
              {t("wed_title")}<br />
          4:45 - 5:45pm <br />
        </>
        }
        link ="/pdfs/IPOS_Operations_Dodd.pdf"
        color = "#1B1534"
        textColor = "white"
        side = "right"
        author = "Joseph Dodd"
      />
      
    </>
  );
}
