import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Hotel from "../assets/images/paradisuscancun.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon  from '@mui/icons-material/LocationOn';

export default function HotelCard() {
  const [imgSize, setImgSize] = React.useState("300");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    if (isMobile) {
      setImgSize("300");
    } else if (isDesktop) {
      setImgSize("450");
    } else setImgSize("500");
  }, [isMobile, isDesktop]);

  const item = {
    display: "flex",
    flexDirection: "column",
    px: 5,
  };
  return (
    <>
      <Box
        component="section"
        sx={{
          flexDirection: "column",
          display: "flex",
          overflow: "hidden",
          mt: 5,
          mb: 5,
        }}
      >
        <Container
          sx={{
            display: "flex",
            position: "relative",
            bgcolor: "#1B1534",
            p: 5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5} align="center">
              <img width={imgSize} src={Hotel} />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item >
                  <Typography
                    variant="h6"
                    sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: "white" }}
                  >
                    Paradisus Cancún
                  </Typography>
                  <br />
                  <Grid
                    container
                    direction="row"
                    spacing={3}
                    sx={{ mb: 3, color: "white" }}
                  >
                    <Grid item>
                      <LocationOnIcon />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">
                      Blvd. Kukulcan km 16.5, Zona Hotelera, 77500 Cancún, Q.R., Mexico <br />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    spacing={3}
                    sx={{ color: "white" }}
                  >
                    <Grid item>
                      <PhoneIcon />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">
                      +52 998 881 1100<br />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2} sx={{ color: "white" }}>
                    <Grid item>
                      
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
