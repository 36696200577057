import { createTheme } from "@mui/material/styles";
import SiemensSans from './fonts/SiemensSans_Prof_Roman.ttf'; 
import SiemensSansBold from './fonts/SiemensSans_Prof_Bold.ttf'; 

const theme = createTheme({
  typography: {
    fontFamily: ['"SiemensSans"'],
  },
  palette: {
    primary: {
      main: '#1B1534',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'SiemensSans';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          letter-spacing: -0.05rem;
          src: local('SiemensSans'), local('SiemensSans_Roman'), url(${SiemensSans}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }, 
        @font-face {
            font-family: 'SiemensSans';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            letter-spacing: -0.05rem;
            src: local('SiemensSans_Bold'), url(${SiemensSansBold}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
      `,
    },
  },
});

export default theme; 